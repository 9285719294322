.jq-checkbox,
.jq-radio {
	vertical-align: -4px;
	width: 20px;
	height: 20px;
	margin: 0 4px 0 0;
	border: 1px solid #C3C3C3;
	background: #FFFFFF;
	box-shadow: 0 1px 1px rgba(0,0,0,.05), inset -1px -1px #FFF, inset 1px -1px #FFF;
	cursor: pointer;
}
.jq-checkbox.focused,
.jq-radio.focused {
	border: 1px solid #08C;
}
.jq-checkbox.disabled,
.jq-radio.disabled {
	opacity: .55;
}
.jq-checkbox {
	border-radius: 3px;
}
.jq-checkbox.checked .jq-checkbox__div {
	width: 8px;
	height: 4px;
	margin: 5px 0 0 5px;
	border-bottom: 2px solid #0a961a;
	border-left: 2px solid #0a961a;
	-webkit-transform: rotate(-50deg);
	transform: rotate(-50deg);
}

.jq-checkbox.rounded {
width:80px;
background:#FFFFFF;
height:40px;
border-radius:30px;
}
.jq-checkbox.rounded .jq-checkbox__div {
	width: 34px;
	height: 34px;
	margin: 0;
	border:none;
	border-radius:50%;
	background:#CCCCCC;
	position:absolute;
	top:3px;
	left:3px;
	-moz-transition: all 0.3s 0.1s;
	-o-transition: all 0.3s 0.1s;
	-webkit-transition: all 0.3s 0.1s;
}
.jq-checkbox.checked.rounded .jq-checkbox__div {
	background:#62b320;
	left:42px;
}



.jq-radio {
	border-radius: 50%;
}
.jq-radio.checked .jq-radio__div {
	width: 12px;
	height: 12px;
	margin: 4px 0 0 4px;
	border-radius: 50%;
	background: #60b226;
}
.jq-file {
	width: 270px;
	border-radius: 4px;
	box-shadow: 0 1px 2px rgba(0,0,0,.1);
}
.jq-file input {
	cursor: pointer;
}
.jq-file__name {
	box-sizing: border-box;
	width: 100%;
	height: 34px;
	padding: 0 80px 0 10px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #FFF;
	box-shadow: inset 1px 1px #F1F1F1;
	font: 14px/32px Arial, sans-serif;
	color: #333;
}
.jq-file__browse {
	position: absolute;
	top: 1px;
	right: 1px;
	padding: 0 10px;
	border-left: 1px solid #CCC;
	border-radius: 0 4px 4px 0;
	background: linear-gradient(#FFF, #E6E6E6);
	box-shadow: inset 1px -1px #F1F1F1, inset -1px 0 #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px/32px Arial, sans-serif;
	color: #333;
	text-shadow: 1px 1px #FFF;
}
.jq-file:hover .jq-file__browse {
	background: linear-gradient(#F6F6F6, #E6E6E6);
}
.jq-file:active .jq-file__browse {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
.jq-file.focused .jq-file__name {
	border: 1px solid #5794BF;
}
.jq-file.disabled,
.jq-file.disabled .jq-file__name,
.jq-file.disabled .jq-file__browse {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}


.jq-number {
	position: relative;
	vertical-align: middle;
	padding: 0;
}
.jq-number__field {
	width: 100px;
	border: 1px solid #CCC;
	border-radius: 3px;
	font-family: 'Montserrat-Medium';
	color: #333;
	box-sizing:border-box;
	background:#FFFFFF;
}
.jq-number.w100, .jq-number.w100 .jq-number__field {
	width: 100%;
}
.jq-number__field:hover {
	border-color: #62b320;
}
.jq-number__field input {
	box-sizing: border-box;
	width: 100%;
	padding: 20px 10px;
	border: none;
	outline: none;
	background: none;
}
.jq-number__spin {
	position: absolute;
	top: 5px;
	right: 5px;
	width: 32px;
	height: 22px;
	border: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
}
.jq-number__spin.minus {
	top: auto;
	bottom: 5px;
}

.jq-number__spin:active {
	background: #EEEEEE;
}
.jq-number__spin:after {
	content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    width: 0;
    height: 0;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #999;
    border-left: 7px solid transparent;
}
.jq-number__spin.minus:after {
	top: 8px;
	border-top: 7px solid #999;
	border-right: 7px solid transparent;
	border-bottom: none;
	border-left: 7px solid transparent;
}
.jq-number__spin.minus:hover:after {
	border-top-color: #62b320;
}
.jq-number__spin.plus:hover:after {
	border-bottom-color: #62b320;
}
.jq-number.focused .jq-number__field {
	border: 1px solid #5794BF;
}
.jq-number.disabled .jq-number__field,
.jq-number.disabled .jq-number__spin {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-number.disabled .jq-number__spin:after {
	border-bottom-color: #AAA;
}
.jq-number.disabled .jq-number__spin.minus:after {
	border-top-color: #AAA;
}



.jq-selectbox {
	vertical-align: middle;
	cursor: pointer;
}
.jq-selectbox.w100 {
	width:100%;
}
.jq-selectbox__select {
    height: 35px;
    padding: 20px 45px 0 20px;
    border: 1px solid #CCC;
    border-radius: 3px;
    background: #FFFFFF;
    font-family: 'Montserrat-Medium';
    color: #333;
}
.jq-selectbox__select:hover {
	background: linear-gradient(#F6F6F6, #E6E6E6);
}
.jq-selectbox__select:active {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
.jq-selectbox.focused .jq-selectbox__select {
	border: 1px solid #5794BF;
}
.jq-selectbox.disabled .jq-selectbox__select {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-selectbox__select-text {
	display: block;
	width: 100%;
}
.jq-selectbox .placeholder {
	color: #888;
}
.jq-selectbox__trigger {
	position: absolute;
	top: 0;
	right: 0;
	width: 34px;
	height: 100%;
	border-left: 1px solid #CCC;
}
.jq-selectbox__trigger-arrow {
	position: absolute;
	top: 25px;
	right: 12px;
	width: 0;
	height: 0;
	border-top: 5px solid #999;
	border-right: 5px solid transparent;
	border-left: 5px solid transparent;
}
.jq-selectbox:hover .jq-selectbox__trigger-arrow {
	border-top-color: #000;
}
.jq-selectbox.disabled .jq-selectbox__trigger-arrow {
	border-top-color: #AAA;
}
.jq-selectbox__dropdown {
	box-sizing: border-box;
	width: 100%;
	margin: 2px 0 0;
	padding: 0;
	border: 1px solid #CCC;
	border-radius: 4px;
	background: #FFF;
	box-shadow: 0 2px 10px rgba(0,0,0,.2);
	font: 14px/18px Arial, sans-serif;
}
.jq-selectbox__search {
	margin: 5px;
}
.jq-selectbox__search input {
	box-sizing: border-box;
	width: 100%;
	margin: 0;
	padding: 5px 27px 6px 8px;
	border: 1px solid #CCC;
	border-radius: 3px;
	outline: none;
	background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAO1JREFUeNqU078LAXEYx/FzYfNzk5TJaFNKYjFYSQZ/hvwBsvg1UCY2xT9gM8hukQGThWRjkcFw3pdnujhfT736Xn2fPvfc3fd07V0OFDDFAnM0ENYsZRiGLSc9OpqIYIA9fMhhjCrW2h9VlMlcH/aymMGtOqEugX08PwQucUZKdTozMIqdTc9WepQD7wjY9ARx+ydwhfyXfS+S0qMcOEQJGcueB3VccFINdMgal6NzkmPjRwJXxDBB7/2RDdtAp6wb+dpphHDASG5QQ0V6u2aoSqBZD/lDrNWRJynLK2qpBn4rc6K2XB9/Nb8EGABtf1thzY6X2AAAAABJRU5ErkJggg==') no-repeat 100% 50%;
	box-shadow: inset 1px 1px #F1F1F1;
	color: #333;
}
.jq-selectbox__not-found {
	margin: 5px;
	padding: 5px 8px 6px;
	background: #F0F0F0;
	font-size: 13px;
}
.jq-selectbox ul {
	margin: 0;
	padding: 0;
}
.jq-selectbox li {
    min-height: 18px;
    padding: 10px 10px 10px;
    color: #333333;
}
.jq-selectbox li.selected {
	background-color: #DDDDDD;
	color: #333333;
}
.jq-selectbox li:hover {
	background-color: #62b320;
	color: #FFF;
}
.jq-selectbox li.disabled {
	color: #AAA;
}
.jq-selectbox li.disabled:hover {
	background: none;
}
.jq-selectbox li.optgroup {
	font-weight: bold;
}
.jq-selectbox li.optgroup:hover {
	background: none;
	color: #231F20;
	cursor: default;
}
.jq-selectbox li.option {
	padding-left: 25px;
}
.jq-select-multiple {
	box-sizing: border-box;
	padding: 1px;
	border: 1px solid #CCC;
	border-bottom-color: #B3B3B3;
	border-radius: 4px;
	background: #FFF;
	box-shadow: inset 1px 1px #F1F1F1, 0 1px 2px rgba(0,0,0,.1);
	font: 14px/18px Arial, sans-serif;
	color: #333;
	cursor: default;
}
.jq-select-multiple.focused {
	border: 1px solid #5794BF;
}
.jq-select-multiple.disabled {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}
.jq-select-multiple ul {
	margin: 0;
	padding: 0;
}
.jq-select-multiple li {
	padding: 3px 9px 4px;
	list-style: none;
}
.jq-select-multiple li:first-child {
	border-radius: 3px 3px 0 0;
}
.jq-select-multiple li:last-child {
	border-radius: 0 0 3px 3px;
}
.jq-select-multiple li.selected {
	background: #08C;
	color: #FFF;
}
.jq-select-multiple li.disabled {
	color: #AAA;
}
.jq-select-multiple li.optgroup {
	font-weight: bold;
}
.jq-select-multiple li.option {
	padding-left: 25px;
}
.jq-select-multiple.disabled li.selected,
.jq-select-multiple li.selected.disabled {
	background: #CCC;
	color: #FFF;
}
input[type='email'].styler,
input[type='password'].styler,
input[type='search'].styler,
input[type='tel'].styler,
input[type='text'].styler,
input[type='url'].styler,
textarea.styler {
	padding: 20px 10px;
	border: 1px solid #CCC;
	border-radius: 3px;
	font-family: 'Montserrat-Medium';
	color: #333;
	box-sizing:border-box;
	font-size:0.9em;
	width:100%;
	box-sizing:border-box;
}
input[type='search'].styler {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
textarea.styler {
	overflow: auto;
}
input[type='email'].styler:hover,
input[type='password'].styler:hover,
input[type='search'].styler:hover,
input[type='tel'].styler:hover,
input[type='text'].styler:hover,
input[type='url'].styler:hover,
textarea.styler:hover {
	border-color: #62b320;
}
input[type='email'].styler:hover:focus,
input[type='password'].styler:hover:focus,
input[type='search'].styler:hover:focus,
input[type='tel'].styler:hover:focus,
input[type='text'].styler:hover:focus,
input[type='url'].styler:hover:focus,
textarea.styler:hover:focus {
	border-color: #62b320;
	outline: none;
	box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
}

a.styler {
text-decoration:none;
}
button.styler,
input[type='button'].styler,
input[type='submit'].styler,
input[type='reset'].styler, a.styler {
	overflow: visible;
	padding: 20px 30px;
	border: none;
	border-radius: 3px;
	outline: none;
	font-family: 'Montserrat-Bold';
	color: #FFFFFF;
	text-transform:uppercase;
	cursor: pointer;
	box-sizing:border-box;
	font-size:0.9em;
	-moz-transition: all 0.3s 0.1s;
	-o-transition: all 0.3s 0.1s;
	-webkit-transition: all 0.3s 0.1s;
	box-shadow:0 2px 6px rgba(10, 150, 26, 0.5);
	box-shadow:0 10px 20px rgba(39, 136, 49, 0.93);
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#62b320+0,449700+100 */
	background: #62b320; /* Old browsers */
	background: -moz-linear-gradient(top, #0f6b04 0%, #1f800b 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #0f6b04 0%,#1f800b 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #0f6b04 0%,#1f800b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0f6b04', endColorstr='#1f800b',GradientType=0 ); /* IE6-9 */
	display:inline-block;
}
button.styler.sta,
input[type='button'].styler.sta,
input[type='submit'].styler.sta,
input[type='reset'].styler.sta, a.styler.sta {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#62b320+0,449700+100 */
	background: #00b121; /* Old browsers */
	background: -moz-linear-gradient(top, #63dc00 0%, #00b121 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #63dc00 0%,#00b121 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #63dc00 0%,#00b121 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#63dc00', endColorstr='#00b121',GradientType=0 ); /* IE6-9 */
	display:inline-block;
	text-shadow:0 1px 2px rgba(0,0,0,0.3);
	text-transform:uppercase;
	font-weight:800;
}
button.styler.styler::-moz-focus-inner,
input[type='button'].styler.styler::-moz-focus-inner,
input[type='submit'].styler.styler::-moz-focus-inner,
input[type='reset'].styler.styler::-moz-focus-inner {
	padding: 0;
	border: 0;
}
button.styler:not([disabled]):hover,
input[type='button'].styler:not([disabled]):hover,
input[type='submit'].styler:not([disabled]):hover,
input[type='reset'].styler:not([disabled]):hover,
input[type='reset'].styler:hover, a.styler:hover  {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#74d326+0,12b700+100 */
	background: #74d326; /* Old browsers */
	background: -moz-linear-gradient(top, #1d9c0e 0%, #279a0f 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #1d9c0e 0%,#279a0f 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #1d9c0e 0%,#279a0f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1d9c0e', endColorstr='#279a0f',GradientType=0 ); /* IE6-9 */
}
button.styler.sta:not([disabled]):hover,
input[type='button'].styler.sta:not([disabled]):hover,
input[type='submit'].styler.sta:not([disabled]):hover,
input[type='reset'].styler.sta:not([disabled]):hover,
input[type='reset'].styler.sta:hover, a.styler.sta:hover  {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#74d326+0,12b700+100 */
	background: #00ce26; /* Old browsers */
	background: -moz-linear-gradient(top, #63dc00 0%, #00ce26 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #63dc00 0%,#00ce26 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #63dc00 0%,#00ce26 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#63dc00', endColorstr='#00ce26',GradientType=0 ); /* IE6-9 */
}
button.styler:active,
input[type='button'].styler:active,
input[type='submit'].styler:active,
input[type='reset'].styler:active {
	background: #F5F5F5;
	box-shadow: inset 1px 1px 3px #DDD;
}
button.styler[disabled],
input[type='button'].styler[disabled],
input[type='submit'].styler[disabled] {
	border-color: #CCC;
	background: #F5F5F5;
	box-shadow: none;
	color: #888;
}


button.styler.border,
input[type='button'].styler.border,
input[type='submit'].styler.border,
input[type='reset'].styler.border, a.styler.border {
	border: 1px solid #FFFFFF;
	color: #FFFFFF;
	box-shadow:none;
	background:none;
}
button.styler.border:not([disabled]):hover,
input[type='button'].styler.border:not([disabled]):hover,
input[type='submit'].styler.border:not([disabled]):hover,
input[type='reset'].styler.border:not([disabled]):hover, a.styler.border:not([disabled]):hover {
	color: #333333;
	background:#FFFFFF;
}

button.styler.border.green,
input[type='button'].styler.border.green,
input[type='submit'].styler.border.green,
input[type='reset'].styler.border.green, a.styler.border.green {
	border: 1px solid #62b320;
	color: #62b320;
	box-shadow:none;
	background:none;
}
button.styler.border.green:not([disabled]):hover,
input[type='button'].styler.green.border:not([disabled]):hover,
input[type='submit'].styler.green.border:not([disabled]):hover,
input[type='reset'].styler.green.border:not([disabled]):hover, a.styler.green.border:not([disabled]):hover {
	color: #FFFFFF;
	background:#62b320;
}



input[type='email'].styler.w100,
input[type='password'].styler.w100,
input[type='search'].styler.w100,
input[type='tel'].styler.w100,
input[type='text'].styler.w100,
input[type='url'].styler.w100,
textarea.styler.w100,
button.styler.w100,
input[type='button'].styler.w100,
input[type='submit'].styler.w100,
input[type='reset'].styler.w100,
a.styler.w100 {
	width:100%;
	text-align:center;
}


/* 520
--------------------------------------------
*/
@media screen and (max-width: 520px) {
button.styler,
input[type='button'].styler,
input[type='submit'].styler,
input[type='reset'].styler, a.styler {
	padding: 20px 15px;
	font-size:0.8em;
}
}