/*!
 * Bootstrap v5.0.2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
//@import "type";
@import "images";
@import "containers";
@import "grid";
@import "forms";
//@import "dropdown";
//@import "nav";
@import "close";
@import "modal";
//@import "carousel";
//@import "offcanvas";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack